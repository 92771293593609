<template>
  <div>
  <div class="main">
    <div style="margin-top: 20%">
        <van-field v-model="value" placeholder="请输入支付金额" type='number' :step="0.001"/>
        <van-button type="info" block style="margin-top:20px" @click="submitPay">确认</van-button>
    </div>
  </div>
  
</div>
</template>

<script>
import { Toast } from "vant";
import md5 from 'js-md5';
import wx from "weixin-js-sdk";
export default {
    name: 'GroupPay',
    data() {
        return {
            group:{
                name:'集团客户',

            },
            base64:'',
            value:'',
            search:'',
			placeholder:'手动输入13位卡号或20位iccid',
			codetrue:false,
            config:{},
            chargeOrderId:'',
            timer:Object,
        }
    },
    methods:{
        payFn(d){
                if (!this.$store.state.wxSDKReady) {
                    setTimeout(this.payFn, 1000);
                    return;
                }
                this.checkHasPay();
                wx.chooseWXPay({
                    timestamp: d.data.timestamp,
                    nonceStr:  d.data.nonceStr,
                    package:   d.data.packAge,
                    signType: "MD5",
                    paySign:   d.data.sign,
                    success: res => {
                    }
                });
        },
        createAccChargeOrder(){
            let params = {amount:this.value,
            // orderForm:1,
            payWay:'weixin-official'};
            this.$api.createAccChargeOrder(params).then(res => {
                if(res.data.code ==0){
                    this.chargeOrderId = res.data.data.orderId
                    this.createAccChargeOrderPay();
                }
            })
        },
        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay(){
            let groupClientUUID = localStorage.getItem("groupClientUUID");
            let groupUUID = localStorage.getItem("groupUUID");
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {
                orderId:this.chargeOrderId, 
                payWay:'weixin-official',
                openId:localStorage.openId,
                clientUuid:groupClientUUID,
                groupUuid:groupUUID
            };
            this.$api.groupPayOrder(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        checkHasPay(){
            let check = async () =>{
                let params = {chargeOrderId:this.chargeOrderId};
                let res = await this.$api.queryHasPayed(params);
                //已经支付
                if(res.data.code==0){
                    this.$router.push({name:'GroupOrder'}); 
                    Toast.success('收款成功');
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await check();
                }
            }
            check();

            // let that = this;
            // let a = setInterval(function(){
            //     let params = {chargeOrderId:that.chargeOrderId};
            //     that.$api.queryHasPayed(params).then(res=>{
            //         //已经支付
            //         if(res.data.code==0){
            //             clearInterval(a);
            //             that.$router.push({name:'GroupOrder'}); 
            //             Toast.success('收款成功');
            //         }
            //     });
            // },1000);
            // this.timer = a;
        },
        submitPay(){
            let groupClientUUID = localStorage.getItem("groupClientUUID");
            let groupUUID = localStorage.getItem("groupUUID");
            this.$api.groupCollectCreate({collectAmount:this.value,clientUuid:groupClientUUID,groupUuid:groupUUID}).then(res => {
            if(res.data.code == 0){
                this.chargeOrderId = res.data.data
                localStorage.setItem("groupOrderId", this.chargeOrderId);
                // this.$router.push('/group/pay/finish'); 
                this.createAccChargeOrderPay();
            }else{
                this.$dialog.alert({message: '订单生成失败'})
            }
            
        })
        }
    },
    created(){
        this.$store.commit('setPageTitle', "收款信息");
    }

}
</script>

<style lang="scss" scoped>
p{
    font-size: 30px;
}
.van-cell{
    font-size:0.5rem;
}

.group{
    margin-top: 20px;
    .van-cell{
        font-size:0.3rem;
        text-align: left;
    }
}



.search-box{
	position:relative;
	width:85%;/*604px*/
	margin:2.4rem auto 0;/*180px*/
}

.search-input{
	width:94%;/*498px*/
	height:2rem;/*78px*/
	border:1px solid #1781ef;/*2px*/
	border-radius:0.3rem;/*12px*/
	font-size:0.8rem;/*28px*/
	padding:.2rem 3%;/*0 82px 0 20px*/
}

.code-icon{
	background-image:url('~@/assets/img/code.png');
	background-size:cover;
	width:1.9rem;/*62px*/
	height:1.9rem;/*62px*/
	position:absolute;
	top:0.3rem;/*9px!Warning*/
	right:0.3rem;/*12px*/
}

.search-btn{
	width:100%;/*604px*/
	height:2.6rem;/*83px*/
	border: none;
	background-color:#197fee;
	border-radius:0.3rem;/*12px*/
	font-size:1rem;/*38px*/
	color:#fff;
	display:block;
  margin-top: 1rem;
}

.main-text,
.msg-text{
	text-align:center;
	font-size:20px;/*24px*/
	color:#838383;
}

.msg{
	margin-top: 10px;
	width:100%;
	float:left;
	/* background-color:#f6f6f6; */
	overflow: hidden;
}

.msg-pho{
	width:80%;/*427px*/
	display:block;
	margin:2rem auto ;/*60px*/
}
.reds{
	color: red;width: 80%;margin: 10px auto;
}


</style>
